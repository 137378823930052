<template>
  <div class="share bg-white">
      <title-top>邀请赚</title-top>
    
    <div class="">
      <img class="shareimg" :src="image_url" alt />
      <div class="w100 column align-center position_a" style="z-index:2;left:0;bottom:100px;">
        <div id="qrCode" ref="qrCodeDiv" style="padding:5px;background:#fff;"></div>
        <span class="font24 mt25" style="padding:8px 12px;border-radius:24px;background:#fff;">邀请码：{{invitation_code}}</span>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import TitleTop from "@/components/common/title/TitleTop";
//import wxapi from "@/common/wxapi.js";
import { dashboard,share_picture, invitation_url } from "network/igou";
import QRCode from 'qrcodejs2';
export default {
  name: "Share",
  data() {
    return {
      image_url: ""
    };
  },
  mounted() {
      this.fn_dashboard();
    this.fn_share_picture();
    this.fn_invitation_url();
  },
  methods: {
      fn_dashboard(){
          dashboard().then(res=>{
          console.log(res)
          this.invitation_code = res.data.invitation_code
        });
      },
    fn_share_picture() {
      share_picture().then(res => {
        console.log(res);
        this.image_url = res.data.image_url;
      });
    },
    fn_invitation_url() {
      invitation_url().then(res => {
        console.log(res);
        new QRCode(this.$refs.qrCodeDiv, {
          text: res.data.url,
          width: 150,
          height: 150,
          colorDark: "#333333", //二维码颜色
          colorLight: "#ffffff", //二维码背景色
          correctLevel: QRCode.CorrectLevel.L//容错率，L/M/H
        })
      });
    }
  },
  components: {
    TitleTop
  }
};
</script>
<style  scoped>
.share {
  height: 100vh;
}
.shareimg {
  /*width: 6.24rem;
  height: 11.093333rem;*/
  width: 100%;
  height: 100%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.fengexian {
  background-color: black;
  height: 1px;
  width: 2.666667rem;
}
.bgcolor {
  width: 1.28rem;
  height: 1.28rem;
  background-color: yellow;
}
</style>
